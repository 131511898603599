const internationalLinks = [
  // International links

  'https://rpc.aztec-international.eu/',
  'https://rpc.aztec-international.eu/history',
  'https://rpc.aztec-international.eu/favorites',
  'https://rpc.aztec-international.eu/alerts',
  'https://rpc.aztec-international.eu/signin',
  'https://rpc.aztec-international.eu/profile',
  'https://rpc.aztec-international.eu/settings',

  // Localhost links (testing)
  // 'http://localhost:3000/',
  // 'http://localhost:3000/history',
  // 'http://localhost:3000/favorites',
  // 'http://localhost:3000/alerts',
  // 'http://localhost:3000/signin',
  // 'http://localhost:3000/profile',
  // 'http://localhost:3000/settings',
]

export default internationalLinks
