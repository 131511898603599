import React, { useEffect, useMemo } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { LoginScreenContextProvider } from '../../contexts/LoginScreenContext'
import { useAlgolia } from '../../contexts/AlgoliaSearchContext'
import Navbar from '../../components/Navbar'
import Home from '../Home'
import History from '../History'
import Favorites from '../Favorites'
import Profile from '../Profile'
import Settings from '../Settings'
import Auth from '../Auth'
import Search from '../Search'
import GenericModal from '../../components/Modals/Generic'
import { store } from '../../store'
import { setErrorMessage } from '../../actions'
import { PageWrapper } from './styles'
import config from '../../rpc.config'
import Alerts from '../Alerts'
import PrivacyPolicy from '../PrivacyPolicy'
import internationalLinks from '../internationalLinks'
import AztecLogo from '../../images/aztec-logo.svg'
import InternationalAztecLogo from '../../images/aztec-international-logo.svg'

const { TIMEOUT_VALUES } = config

const ProtectedRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={() =>
      store.getState().currentUser ? children : <Redirect to='/signin' />
    }
  />
)

const LoginRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={() =>
      !store.getState().currentUser ? children : <Redirect to='/' />
    }
  />
)

const Main = ({ deviceType }) => {
  const location = useLocation()
  const companyLogo = useMemo(() => {
    const currentUrl = window.location.href
    const internationalUrlRegex = /(https:\/\/rpc\.aztec-international.*)/g // when testing use /(http:\/\/localhost.*)/g
    const filteredInternationalLinks = internationalLinks.filter((item) =>
      item.match(internationalUrlRegex)
    )
    return filteredInternationalLinks.some((link) => link === currentUrl)
      ? InternationalAztecLogo
      : AztecLogo
  })

  const { selectedChips, searchText } = useAlgolia()
  const history = useHistory()

  useEffect(() => {
    if (
      (searchText.length >= 3 || selectedChips.length > 0) &&
      location.pathname !== '/search'
    ) {
      history.push('/search')
    }
  }, [selectedChips, searchText])

  return (
    <div>
      <GenericModal
        text={store.getState().errorMessage}
        show={store.getState().errorMessage.length > 0}
        onExited={() => store.dispatch(setErrorMessage(''))}
      />
      <LoginScreenContextProvider>
        {deviceType === 'DESKTOP' && <Navbar companyLogo={companyLogo} />}
        <PageWrapper>
          <TransitionGroup className='animation-wrapper'>
            <CSSTransition
              timeout={TIMEOUT_VALUES}
              classNames='page-dissolve'
              key={location.key}
            >
              <Switch location={location}>
                <Route exact path='/'>
                  <Home />
                </Route>
                <Route path='/history'>
                  <History />
                </Route>
                <Route path='/favorites'>
                  <Favorites />
                </Route>
                <Route path='/search'>
                  <Search />
                </Route>
                <Route path='/alerts'>
                  <Alerts />
                </Route>
                <ProtectedRoute path='/profile'>
                  <Profile />
                </ProtectedRoute>
                <ProtectedRoute path='/settings'>
                  <Settings />
                </ProtectedRoute>
                <LoginRoute path='/signin'>
                  <Auth />
                </LoginRoute>
                <Route path='/privacy-policy'>
                  <PrivacyPolicy />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </PageWrapper>
      </LoginScreenContextProvider>
    </div>
  )
}

export default Main
