const texts = {
  common: {
    or: 'lub',
    ok: 'OK',
    cancel: 'Anuluj',
    delete: 'Skasuj',
    notApplicable: 'Nie dotyczy',
    yes: 'tak',
    no: 'nie',
    send: 'Wysłać',
  },
  modals: {
    passwordConfirmation: 'Aby kontynuować, podaj swoje hasło.',
  },
  main: {
    loading: 'Ładowanie aplikacji...',
    mobile: 'Roof Pitch Calculator jest dostępny w sklepie Google Play.',
    getItOnGooglePlay: 'Pobierz z Google Play',
  },
  navbar: {
    history: 'Historia',
    favorties: 'Ulubione',
    about: 'O',
    signIn: 'Zaloguj się',
    signUp: 'Zapisz się',
    profile: 'Profil',
    settings: 'Ustawienia',
    logOut: 'Wyloguj',
  },
  search: {
    lookingFor: 'Szukam uszczelnienia do',
    recent: 'Ostatnie wyszukiwania',
    title: 'Szukaj',
    noResults: 'Nie znaleźliśmy uszczelnienia pasującego do zapytania.',
    noMoreResults: 'Brak uszczelnień do wyświetlenia.',
    chips: {
      round: 'Rury okrągłe',
      square: 'Rury kwadratowe',
      standard: 'Montaż standardowy - nasuwany w dół rury',
      retrofit: 'Montaż typu retrofit - nałożenie na rurę od boku',
      epdm: 'Wersja EPDM',
      silicone: 'Wersja silikonowa',
      residential: 'Dach z gontu lub dachówki',
      metal: 'Dachy blaszane',
      sloped: 'Uszczelnienia skośne',
      flat: 'Uszczelnienia płaskie',
    },
    clearSelection: 'Wyczyść wybór',
  },
  favorites: {
    title: 'Ulubione',
    noFavorites: 'Jeszcze nie masz ulubionych do wyświetlenia.',
    end: 'Brak uszczelnień do wyświetlenia.',
  },
  aboutModal: {
    versionText: 'Wersja aplikacji',
    developedBy: 'Dumnie opracowany przez',
    privacyPolicy: {
      title: 'Polityka prywatności',
      content: [
        "Your privacy is important to us. It is Sauvara's policy to respect your privacy regarding any information we may collect from you across our website, http://rpc.aztecwasher.com, and other sites we own and operate.",
        "We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.",
        "We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.",
        "We don't share any personally identifying information publicly or with third-parties, except when required to by law.",
        'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.',
        'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.',
        'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. Feel free to contact us at support@sauvara.com if you have any questions about how we handle user data and personal information.',
        'This policy is effective as of 10 February 2020.',
      ],
    },
    contactUs: {
      title: 'Skontaktuj się z nami',
      content: [
        'Możesz się z nami skontaktować w dowolnym momencie. Napisz do nas na adres sales@aztec-international.eu jeśli masz jakieś pytania, sugestie lub potrzebujesz dodatkowej pomocy w obsłudze Master Flash Roof® Pitch Calculator.',
        'Zapraszamy również do kontaktu w przypadku dodatkowych pytań dotyczących naszych produktów z serii Master Flash®.',
        'Z przyjemnością pomożemy.',
      ],
    },
  },
  alerts: {
    title: 'Alerty',
    noAlerts: 'Nie ma jeszcze alertów do wyświetlenia.',
  },
  languages: {
    en: 'Angielski',
    es: 'Hiszpański',
    pl: 'Polski',
  },
  navigation: {
    next: 'Następny',
    back: 'Wstecz',
    newSearch: 'Nowe wyszukiwanie',
  },
  tips: {
    pressAltRightToGoNext:
      'Po wprowadzeniu wymaganych informacji naciśnij alt (lub opcję na komputerach Mac) + strzałkę w prawo lub kliknij przycisk Dalej, aby przejść do następnego ekranu',
    pressAltLeftToGoBack:
      'Naciśnij alt (lub opcję na komputerach Mac) + strzałkę w lewo lub kliknij przycisk Wstecz, aby wrócić do poprzedniego ekranu',
  },
  pipeSize: {
    title: 'Wielkość rury',
    pageText: [
      'Podaj rozmiar rury w calach lub milimetrach, a nasze narzędzie zajmie się przeliczeniem wartości tylko w celach informacyjnych.',
      'W przypadku rur ze specyfikacją w calach można wprowadzić dowolną wartość od 1/16 do 39-1/2. W takim przypadku powinieneś',
      'W przypadku rur ze specyfikacją w milimetrach można wprowadzić dowolną wartość liczbową od 1,5875 do 1003,3.',
    ],
    instructions: {
      readTheInstructions: 'Przeczytaj instrukcję',
      text: [
        'Cale ułamkowe są dopuszczalne w następującym formacie 1/16 lub 2-1/16, ale dozwolone są tylko następujące ułamki: 1/16, 1/8, 3/16, 1/4, 5/16, 3/8, 7 /16, 1/2, 9/16, 5/8, 11/16, 3/4, 13/16, 7/8 i 15/16.',
        ' Możesz nawet wstawić cale dziesiętne, takie jak 0,0625 lub 2,0625 (to samo, co odpowiednio 1/16 i 2-1/16).',
      ],
    },
    labelIn: 'Cale',
    labelMm: 'Milimetry',
    errorIn:
      'nie jest prawidłową wartością. Wprowadź prawidłową wartość. Przeczytaj powyższe instrukcje, aby uzyskać pomoc.',
    errorMm:
      'nie jest prawidłową wartością. Wprowadź wartość z zakresu od 1,5875 do 1003,3.',
  },
  pipeType: {
    title: 'Typ rury',
    text: 'Czy rura jest okrągła czy kwadratowa? Wybierz właściwy rodzaj poniżej.',
    labelRound: 'Okrągła',
    labelSquare: 'Kwadratowa',
  },
  roofType: {
    title: 'Rodzaj dachu',
    text: 'Jaki jest rodzaj dachu? Wybierz właściwy poniżej.',
    labelMetal: 'Blaszany',
    labelResidential: 'Gont lub dachówka',
  },
  roofPitch: {
    title: 'Nachylenie',
    text: 'Czy dach jest pochyły czy płaski? Wybierz właściwy poniżej.',
    labelSloped: 'Pochyły',
    labelFlat: 'Płaski',
    step: 'Krok',
  },
  weatherProtection: {
    title: 'Ochrona przed warunkami atmosferycznymi',
    text: 'Wykonany z EPDM lub czerwonego silikonu High Temp.',
    labelEPDM: 'EPDM',
    labelSilicone: 'Silikon',
  },
  applicationType: {
    title: 'Typ aplikacji',
    text: 'Wybierz sposób montażu uszczelnienia na rurze.',
    labelStandard: 'Standardowy - nasunięcie od góry',
    labelRetrofit: 'Retrofit - nałożenie od boku',
  },
  results: {
    results: 'Wyniki',
    title: 'Oto lista produktów Master Flash® rekomendowanych do użycia',
    success:
      'Kliknij dowolny produkt poniżej, aby zobaczyć jego pełne informacje.',
    noResultsForm: {
      sorry: 'Przepraszamy',
      noProductFound:
        'Wygląda na to, że Twoja prośba może wymagać niestandardowego rozwiązania, a my chcielibyśmy podjąć to wyzwanie! Podaj poniżej swoje dane kontaktowe. Przedstawiciel handlowy wkrótce skontaktuje się z Tobą w sprawie Twoich potrzeb.',
      scrollDown: 'Przewiń w dół, aby się z nami skontaktować',
      imgAltText: 'Dłoń trzymająca kopertę e-mail wewnątrz tabletu',
      form: {
        title: 'Skontaktuj się z nami',
        subtitle: 'Proszę podać swoje dane poniżej.',
        sent: 'Pomyślnie wysłane',
      },
    },
  },
  inputTexts: {
    email: {
      label: 'E-mail',
      placeholder: 'Wprowadź swój email',
      validation: 'Proszę wpisać poprawny adres e-mail',
    },
    password: {
      label: 'Hasło',
      placeholder: 'Wprowadź hasło',
      repeatYourPassword: 'Powtórz swoje hasło',
    },
    name: {
      label: 'Nazwa',
      placeholder: 'Wpisz swoje imię',
    },
    phone: {
      label: 'Telefon',
      placeholder: 'Wprowadź swój numer telefonu',
    },
    generic: {
      optional: 'opcjonalny',
      required: 'To pole jest wymagane',
    },
  },
  login: {
    welcome: 'Hello! Welcome to Roof Pitch Calculator',
    loginWith: 'Zaloguj się z',
    loginForm: {
      text: 'Wpisz swój adres e-mail i hasło, aby zalogować się na swoje konto.',
      rememberMe: 'Zapamiętaj mnie',
      signIn: 'Zaloguj',
      dontHaveAnAccount: 'Nie masz konta?',
      forgotPassword: 'Zapomniałeś hasła?',
    },
    signupForm: {
      signUp: 'Zapisz się',
      text: 'Utwórz konto w zaledwie kilku krokach za pomocą adresu e-mail i hasła.',
    },
    passwordRecovery: {
      title: 'Zapomniałeś hasła?',
      dontWorry: 'Nie martw się, pomożemy.',
      enterTheEmail: 'Wprowadź adres e-mail powiązany z Twoim kontem.',
      success: {
        title: 'Gotowe!',
        youCanCheck: 'Możesz teraz sprawdzić swoją skrzynkę odbiorczą.',
        enter:
          'Proszę postępować zgodnie z instrukcjami, które właśnie do Ciebie wysłaliśmy, aby zresetować hasło.',
      },
      fail: {
        oops: 'Ups!',
        somethingWentWrong: 'Coś poszło nie tak.',
        reEnter: 'Wprowadź ponownie swój adres e-mail i spróbuj ponownie.',
      },
    },
    orContinueWith: 'lub kontynuuj z',
    errorMessages: {
      email: 'Adres email jest nieprawidłowy.',
      password: 'Hasło musi mieć co najmniej 6 znaków.',
      passwordRepeat: 'Hasła się nie zgadzają.',
    },
  },
  profile: {
    accountDetails: 'Szczegóły Konta',
    name: 'Nazwa',
    email: 'E-mail',
    password: 'Hasło',
    settings: 'Ustawienia',
    language: {
      title: 'Język',
      text: 'Wybierz preferowany język:',
    },
    notifications: 'Powiadomienia',
    noName: 'Nie zarejestrowano jeszcze użytkowanika.',
    noPassword:
      'Nie masz hasła. Możesz zaktualizować swój adres e-mail po jego utworzeniu.',
    noPasswordRegistered: 'Nie zarejestrowano jeszcze hasła.',
    setPassword: 'Nie masz hasła. Utwórz nowe.',
    deleteAccount: {
      title: 'Usuń konto',
      text: 'Czy na pewno chcesz usunąć swoje konto Kalkulatora nachylenia dachu?',
      confirm: 'Potwierdzam, że chcę usunąć swoje konto.',
    },
    passwordUpdate: {
      currentPassword: 'Aktualne hasło',
      newPassword: 'Nowe hasło',
      newPasswordRepeat: 'Wprowadź ponownie nowe hasło',
      passwordsAreEqual: 'Hasła są takie same.',
    },
  },
  flashingProperties: {
    name: 'Nazwa',
    sku: 'SKU',
    pipeSizeIn: 'Min./maks. rozmiar rury w calach',
    pipeSizeMm: 'Min./maks. rozmiar rury w milimetrach',
    baseIn: 'Podstawa w calach',
    baseMm: 'Podstawa w milimetrach',
    heightIn: 'Wysokość w calach',
    heightMm: 'Wysokość w milimetrach',
    roofPitch: 'Nachylenie dachu',
    siliconeColors: 'Dostępne kolory dla wersji silikonowej',
    epdmColors: 'Dostępne kolory dla wersji EPDM',
    isRound: 'Okrągły',
    isSquare: 'Kwadratowy',
    isMetal: 'Metal',
    isNonMetal: 'Non-metal',
    isStandard: 'Standard',
    isRetrofit: 'Retrofit',
    isEpdm: 'EPDM',
    isSilicone: 'Silikon',
  },
  history: {
    title: 'Historia',
    noUser: [
      'Ta funkcja jest dostępna tylko dla zarejestrowanych użytkowników. Kliknij',
      'tutaj',
      'zalogować się.',
    ],
    empty:
      'Nie ma jeszcze dziennika do wyświetlenia. Rozpocznij wyszukiwanie obróbek blacharskich, a Twoje wyszukiwania zostaną wyświetlone tutaj.',
  },
  errorHandlerMessages: {
    documentDoesntExit: 'Dokument nie istnieje!',
    userNotFound:
      'Brak rekordu użytkownika odpowiadającego temu identyfikatorowi. Użytkownik mógł zostać usunięty.',
    wrongPassword: 'Hasło jest nieprawidłowe lub użytkownik nie ma hasła.',
    userTokenExpired:
      'Dane logowania użytkownika straciły ważność. Użytkownik musi się ponownie zalogować.',
    emailAlreadyInUse: 'Adres e-mail jest już używany przez inne konto.',
    accountExistsWithDifferentCredential:
      'Konto już istnieje z tym samym adresem e-mail, ale innymi danymi logowania. Zaloguj się za pomocą danych powiązanych z tym adresem e-mail.',
    requiresRecentAuth:
      'Ta operacja jest wrażliwa i wymaga niedawnego uwierzytelnienia. Zaloguj się ponownie przed ponowną próbą tego żądania.',
    networkError:
      'Wystąpił błąd sieci (np. przekroczenie limitu czasu, przerwane połączenie lub nieosiągalny host).',
    tooManyAttempts:
      'Dostęp do tego konta został tymczasowo wyłączony z powodu wielu nieudanych prób logowania. Możesz je natychmiast przywrócić, resetując hasło, lub możesz spróbować ponownie później.',
  },
}

export default texts
