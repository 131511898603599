const texts = {
  common: {
    or: 'o',
    ok: 'OK',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    notApplicable: 'N/A',
    yes: 'Sí',
    no: 'No',
    send: 'Enviar',
  },
  modals: {
    passwordConfirmation: 'Ingresa tu contraseña para continuar.',
  },
  main: {
    loading: 'Cargando aplicación...',
    mobile: 'Roof Pitch Calculator está disponible en la tienda Google Play.',
    getItOnGooglePlay: 'Disponible en Google Play',
  },
  navbar: {
    history: 'Historial',
    favorties: 'Favoritos',
    about: 'Acerca de',
    signIn: 'Iniciar sesión',
    signUp: 'Regístrate',
    profile: 'Perfil',
    settings: 'Configuración',
    logOut: 'Cerrar sesión',
  },
  search: {
    lookingFor: 'Estoy buscando flashings que apoye',
    recent: 'Búsquedas recientes',
    title: 'Búsqueda',
    noResults: 'No hay flashings que coincidan con su búsqueda.',
    noMoreResults: 'No hay más flashings para exibir.',
    chips: {
      round: 'Tubos redondos',
      square: 'Tubos cuadrados',
      standard: 'Aplicación estándar',
      retrofit: 'Aplicación de retrofit',
      epdm: 'Protección EPDM',
      silicone: 'Protección de silicona',
      residential: 'Techos residenciales',
      metal: 'Techos de metal',
      sloped: 'Flashings inclinados',
      flat: 'Flashings planos',
    },
    clearSelection: 'Remover selección',
  },
  favorites: {
    title: 'Favoritos',
    noFavorites: 'Todavía no hay flashings favoritos para mostrar.',
    end: 'No hay más flashings para exibir.',
  },
  aboutModal: {
    versionText: 'Versión de la aplicación',
    developedBy: 'Orgullosamente desarrollado por',
    privacyPolicy: {
      title: 'Política de Privacidad',
      content: [
        'Su privacidad es importante para nosotros. Es política de Sauvara respetar su privacidad con respecto a cualquier información que podamos obtener de usted en nuestro sitio web, http://rpc.aztecwasher.com, y otros sitios que sean de nosotros y administremos.',
        'Únicamente solicitamos información personal si es necesaria para brindarle un servicio. Esta información la recolectamos de manera justa y legal, con su conocimiento y consentimiento. También le informamos los motivos para recopilarla y cómo se utilizará.',
        'Solamente retenemos la información recopilada durante el tiempo que sea necesario para brindarle el servicio solicitado. La información almacenada está protegida dentro de medios comercialmente aceptables para evitar pérdidas y robos, así como acceso, divulgación, copia, uso o modificaciones no autorizadas.',
        'No compartimos públicamente o con terceros ninguna información de identificación personal, únicamente si la ley lo requiere.',
        'Nuestro sitio web puede tener enlaces a sitios externos que no son operados por nosotros. Tome en cuenta que no tenemos control sobre el contenido y las prácticas de estos sitios, y no podemos aceptar responsabilidad u obligación por sus respectivas políticas de privacidad.',
        'Usted es libre de rechazar nuestra solicitud de información personal, comprendiendo que es posible que no podamos brindarle algunos de los servicios que desea.',
        'El uso continuo de nuestro sitio web se considerará como la aprobación de nuestras prácticas referentes a la privacidad y la información personal. No dude en contactarnos al correo support@sauvara.com si tiene preguntas acerca de cómo manejamos los datos del usuario y la información personal.',
        'Esta política es efectiva a partir del 10 de febrero del 2020.',
      ],
    },
    contactUs: {
      title: 'Contacto',
      content: [
        'Contáctenos en support@sauvara.com si tiene alguna sugerencia o necesita ayuda para hacer uso de Master Flash Roof® Pitch Calculator.',
        'No dude en contactarnos en info@aztecwahser.com si usted tiene preguntas sobre nuestros productos Master Flash®.',
        'Estaremos complacidos de ayudarle.',
      ],
    },
  },
  alerts: {
    title: 'Alertas',
    noAlerts: 'Aún no hay alertas para mostrar.',
  },
  languages: {
    en: 'Inglés',
    es: 'Español',
    pl: 'Polaco',
  },
  navigation: {
    next: 'Siguiente',
    back: 'Volver',
    newSearch: 'Nueva búsqueda',
  },
  tips: {
    pressAltRightToGoNext:
      'Después de ingresar la información requerida, presione alt (u opción en Mac) + flecha derecha o haga clic en el botón siguinte para ir a la siguiente pantalla',
    pressAltLeftToGoBack:
      'Presione alt (u opción en Mac) + flecha izquierda o haga clic en el botón volver para volver a la pantalla anterior',
  },
  pipeSize: {
    title: 'Tamaño de la tubería',
    pageText: [
      'Por favor, ingrese el tamaño de la tubería en pulgadas o milímetros y nuestra herramienta se encargará de convertir su valor para su referencia.',
      'Para tuberías con especificaciones en pulgadas, puede ingresar cualquier valor de 1/16 a 39-1/2. En este caso debe',
      'Para tuberías con especificaciones en milímetros, puede ingresar cualquier valor numérico de 1.5875 a 1003.3.',
    ],
    instructions: {
      readTheInstructions: 'Leer las instrucciones',
      text: [
        'Las pulgadas fraccionarias son admitidas en el siguiente formato: 1/16 o 2-1/16 pero solamente se permiten las siguientes fracciones: 1/16, 1/8, 3/16, 1/4, 5/16, 3/8, 7/16, 1/2, 9/16, 5/8, 11/16, 3/4, 13/16, 7/8 y 15/16.',
        ' También puede introducir pulgadas decimales como: 0.0625 o 2.0625 (igual que 1/16 y 2-1/16 respectivamente).',
      ],
    },
    labelIn: 'Pulgadas',
    labelMm: 'Milímetros',
    errorIn:
      'No es un valor válido. Por favor ingrese una cifra válida. Lea las instrucciones anteriores para obtener ayuda.',
    errorMm:
      'No es un valor válido. Por favor ingrese una cifra válida entre 1.5875 y 1003.3.',
  },
  pipeType: {
    title: 'Tipo de tubería',
    text: '¿La tubería es redonda o cuadrada? Selecciona la opción correcta.',
    labelRound: 'Redonda',
    labelSquare: 'Cuadrada',
  },
  roofType: {
    title: 'Tipo de techo',
    text: '¿Cuál es el tipo de techo? Selecciona la opción correcta.',
    labelMetal: 'Metálico',
    labelResidential: 'Residencial',
  },
  roofPitch: {
    title: 'Inclinación del techo',
    text: '¿El techo es inclinado o plano? Elige la opción deseada.',
    labelSloped: 'Inclinado',
    labelFlat: 'Plano',
    step: 'Etapa',
  },
  weatherProtection: {
    title: 'Protección Climática',
    text: 'Hecho de EPDM o silicona roja de alta temperatura.',
    labelEPDM: 'EPDM',
    labelSilicone: 'Silicona',
  },
  applicationType: {
    title: 'Tipo de Aplicación',
    text: '¿Es para una instalación existente o retrofit? Selección la opción correcta.',
    labelStandard: 'Estándar',
    labelRetrofit: 'Retrofit',
  },
  results: {
    results: 'Resultados',
    title: 'Esta es la lista que Master Flash® products le recomienda',
    success: 'Haz clic en cualquier producto para ver su información completa.',
    noResultsForm: {
      sorry: 'Lo sentimos',
      noProductFound:
        'Parece que su solicitud podría necesitar una solución especial, ¡y nos gustaría aceptar este desafío! Por favor, ingrese su información de contacto. En breve un representante de ventas se pondrá en contacto con usted.',
      scrollDown: 'Desplácese hacia abajo para contactarnos',
      imgAltText:
        'Mano sosteniendo un sobre de correo electrónico dentro de un tablet',
      form: {
        title: 'Contactanos',
        subtitle: 'Por favor, ingrese sus datos de contacto abajo.',
        sent: 'Enviado con exito',
      },
    },
  },
  inputTexts: {
    email: {
      label: 'Correo electrónico',
      placeholder: 'Ingresar correo electrónico',
      validation: 'Por favor inserte un correo electrónico válido',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Ingresar contraseña',
      repeatYourPassword: 'Confirmar contraseña',
    },
    name: {
      label: 'Nombre',
      placeholder: 'Ingresar nombre',
    },
    phone: {
      label: 'Teléfono',
      placeholder: 'Ingresar número telefónico',
    },
    generic: {
      optional: 'opcional',
      required: 'Este campo es obligatorio',
    },
  },
  login: {
    welcome: '¡Hola! Bienvenido a Roof Pitch Calculator',
    loginWith: 'Inicie sesión con',
    loginForm: {
      text: 'Ingrese su correo electrónico y contraseña para iniciar sesión en su cuenta.',
      rememberMe: 'Recordarme',
      signIn: 'Iniciar sesión',
      dontHaveAnAccount: '¿No tiene una cuenta?',
      forgotPassword: '¿Olvidó su contraseña?',
    },
    signupForm: {
      signUp: 'Regístrate',
      text: 'Regístrese en pocos pasos ingresando su correo electrónico y contraseña.',
    },
    passwordRecovery: {
      title: '¿Olvidó su contraseña?',
      dontWorry: 'No se preocupe, reestablecer su contraseña es fácil.',
      enterTheEmail:
        'Ingrese la dirección de correo electrónico con la que se registró.',
      success: {
        title: '¡Listo!',
        youCanCheck: 'Puede consultar su bandeja de entrada.',
        enter:
          'Por favor, siga las instrucciones que le enviamos para restablecer su contraseña.',
      },
      fail: {
        oops: '¡Lo siento!',
        somethingWentWrong: 'Algo salió mal.',
        reEnter: 'Escribe de nuevo tu correo electrónico e intenta otra vez.',
      },
    },
    orContinueWith: 'o inicia sesión con',
    errorMessages: {
      email: 'Correo electrónico no válido.',
      password: 'La contraseña debe tener al menos 6 caracteres.',
      passwordRepeat: 'Las contraseñas no coinciden.',
    },
  },
  profile: {
    accountDetails: 'Detalles de la cuenta',
    name: 'Nombre',
    email: 'Correo electrónico',
    password: 'Contraseña',
    settings: 'Ajustes',
    language: {
      title: 'Idioma',
      text: 'Selecciona tu idioma:',
    },
    notifications: 'Notificaciones',
    noName: 'Aún no se ha registrado ningún nombre.',
    noPassword:
      'No tiene contraseña. Puede actualizar su correo electrónico una vez que tenga una.',
    noPasswordRegistered: 'Aún no se ha registrado ninguna contraseña.',
    setPassword: 'No tiene contraseña. Por favor crea una.',
    deleteAccount: {
      title: 'Eliminar cuenta',
      text: '¿Esta seguro que quiere eliminar su cuenta de Roof Pitch Calculator?',
      confirm: 'Confirmo que quiero eliminar mi cuenta.',
    },
    passwordUpdate: {
      currentPassword: 'Contraseña actual',
      newPassword: 'Nueva contraseña',
      newPasswordRepeat: 'Confirmar nueva contraseña',
      passwordsAreEqual: 'Las contraseñas son iguales.',
    },
  },
  flashingProperties: {
    name: 'Nombre',
    sku: 'SKU',
    pipeSizeIn: 'Tamaño de tubería mínimo/máximo en pulgadas',
    pipeSizeMm: 'Tamaño de tubería mínimo/máximo en milímetros',
    baseIn: 'Base en pulgadas',
    baseMm: 'Base en milímetros',
    heightIn: 'Altura en pulgadas',
    heightMm: 'Altura en milímetros',
    roofPitch: 'Roof pitch',
    siliconeColors: 'Color (es) de silicona disponibles',
    epdmColors: 'Color (es) de EPDM disponibles',
    isRound: 'Redondo',
    isSquare: 'Cuadrado',
    isMetal: 'Metálico',
    isNonMetal: 'Residencial',
    isStandard: 'Estándar',
    isRetrofit: 'Retrofit',
    isEpdm: 'EPDM',
    isSilicone: 'Silicona',
  },
  history: {
    title: 'Historial',
    noUser: [
      'Esta función está disponible solo para usuarios registrados. Haz clic',
      'aquí',
      'para iniciar sesión.',
    ],
    empty:
      'Aún no hay ningún registro para mostrar. Empieza a buscar flashings y sus búsquedas se mostrarán aquí.',
  },
  errorHandlerMessages: {
    documentDoesntExit: '¡El documento no existe!',
    userNotFound:
      'No hay registro de usuario que corresponda con este identificador. El usuario puede haber sido eliminado.',
    wrongPassword:
      'La contraseña no es válida o el usuario aún no cuenta con contraseña.',
    userTokenExpired:
      'La credencial del usuario ya no es válida. El usuario debe iniciar sesión nuevamente.',
    emailAlreadyInUse:
      'La dirección de correo electrónico ya está siendo utilizada por otra cuenta.',
    accountExistsWithDifferentCredential:
      'Ya existe una cuenta con la misma dirección de correo electrónico pero con diferentes credenciales de inicio de sesión. Inicie sesión con un proveedor asociado a esta dirección de correo electrónico.',
    requiresRecentAuth:
      'Esta operación es sensible y requiere una nueva autenticación. Para volver a intentar esta solicitud, inicie sesión nuevamente.',
    networkError:
      'Ha ocurrido un error en la red (puede ser por el tiempo de espera, conexión interrumpida o host inaccesible).',
    tooManyAttempts:
      'El acceso a esta cuenta se ha desactivado temporalmente debido a los intentos fallidos de inicio de sesión. Puede recuperarla inmediatamente reestableciendo su contraseña o puede intentarlo más tarde.',
  },
}

export default texts
