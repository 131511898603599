import styled from 'styled-components'

export const About = styled.div`
  position: relative;
  cursor: pointer;

  & > span {
    color: ${(props) => (props.active ? '#CFB26C' : 'inherit')};
  }
`

export const AboutModalWrapper = styled.div`
  position: absolute;
  left: 14px;
  top: 36px;
  width: 300px;
  min-height: 135px;
  max-height: 400px;
  background-color: #414141;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 28px 20px;
  cursor: default;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.1;
  color: #e5e5e5;
  z-index: 2;
`

export const AppVersionModalWrapper = styled.div`
  overflow: hidden;
  position: relative;
  transition: height 200ms ease-in;

  &.showing-MENU {
    height: ${({ height }) => `${height}px`};
  }
  &.showing-POLICY {
    height: 352px;
  }
  &.showing-CONTACT {
    height: 202px;
  }
`

export const AppVersionMenu = styled.div`
  position: absolute;
  width: 100%;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0%)' : 'translateX(-100%)'};
  height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  transition: transform 200ms ease-in;
`

export const VersionWrapper = styled.div`
  font-size: 10px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 26px;

  & > p a {
    text-decoration: underline;
  }
`

export const MenuItemWrapper = styled.div`
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:nth-child(1) {
    padding-bottom: 14px;
    border-bottom: 1px solid #c4c4c4;
  }

  &:nth-child(2) {
    padding-top: 14px;
  }
`

export const SecondContentWrapper = styled.div`
  transform: ${({ isShowing }) =>
    isShowing ? 'translateX(0%)' : 'translateX(100%)'};
  height: ${({ isShowing }) => (isShowing ? '100%' : '0')};
  transition: transform 200ms ease-in;
`

export const MenuContentTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`

export const SecondContentText = styled.div`
  max-height: 314px;
  overflow: auto;

  & > p {
    margin-bottom: 14px;
    cursor: auto;
    font-size: 14px;
    line-height: 1.25;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
`
